import React from "react";
import loadable from "@loadable/component";

const Hero = loadable(() => import("../modules/hero"));
const ImageCTA = loadable(() => import("../modules/imageCTA"));
const OrganisationFeatures = loadable(() =>
  import("../modules/organisationFeatures")
);
const ServicesSection = loadable(() => import("../modules/servicesSection"));
const Contact = loadable(() => import("../modules/contact"));

const getModule = (module) => {
  const type = module._type;
  const modules = {
    hero: Hero,
    imageCTA: ImageCTA,
    organisationFeatures: OrganisationFeatures,
    servicesSection: ServicesSection,
    contactSection: Contact,
    default: () => <span className="h1">{type}</span>,
  };
  const Module = modules[type] || modules["default"];
  return <Module data={module} />;
};

export const Modules = ({ reactModule }) => getModule(reactModule);

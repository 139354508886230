/** @jsxImportSource theme-ui */
import * as React from "react";

import SEO from "../components/seo";
import Layout from "../components/layout";
import RenderModules from "../utils/renderModules";

const Page = ({ path, pageContext }) => {
  const { modules, title, slug, site, meta } = pageContext;

  const url = slug === "home" ? "" : path;
  return (
    <>
      <SEO
        defaultMeta={site.defaultMeta}
        defaultTitle={
          slug === "home"
            ? "North Ridge Clinic - Holistic family healthcare providers"
            : `North Ridge Clinic - ${title}`
        }
        metaInfo={meta}
        pagePath={url}
      />
      <Layout>{RenderModules(modules)}</Layout>
    </>
  );
};

export default Page;

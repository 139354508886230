/** @jsxImportSource theme-ui */
import * as React from "react";

const Layout = ({ children }) => {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div
        sx={{
          width: "100%",
          flex: "1 1 auto",
        }}
      >
        {children}
      </div>
      <Footer />
    </div>
  );
};

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer
      sx={{
        py: "4rem",
        textAlign: "center",
        color: "#fff",
        bg: "primary",
      }}
    >
      © {year} North Ridge Clinic. All rights reserved.
    </footer>
  );
};

export default Layout;
